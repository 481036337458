<template>
  <div>
    <div class="form-group row">
      <div class="col-lg-6 mb-5">
        <label>{{ $t('filter_status') }}</label>
        <select name="" id="status" v-model="from_status" @input="getInvoices" class="custom-select">
          <option v-for="row in statusList" :value="row.id" :key="row.id" :disabled="checkIgnoreSelectStatus(row.id)">{{ row.title }}</option>
        </select>

      </div>
      <div class="col-lg-6 mb-5">
        <label>{{ $t('invoice') }}</label>
        <multiselect v-model="invoice"
                     :placeholder="$t('invoice')"
                     label="invoice_code"
                     track-by="id"
                     :options="invoices_list"
                     :multiple="false"
                     :taggable="false"
                     :show-labels="false"
                     :show-no-options="false"
                     @input="selectInvoice"
                     :show-no-results="false">
        </multiselect>
        <span v-if="validation && validation.id" class="fv-plugins-message-container invalid-feedback">
            {{ validation.id[0] }}
        </span>
      </div>
      <div class="col-lg-6 mb-5">
        <p class="mt-10">{{$t('invoice_date')}}: {{invoice_date}}</p>
      </div>
      <div class="col-lg-6 mb-5">
        <label>{{ $t('status_to_be_converted_to') }}</label>
        <select name="" id="status" v-model="data.status" class="custom-select" :class="validation && validation.status ? 'is-invalid' : ''">
          <option v-for="row in statusList" :value="row.id" :key="row.id" :disabled="checkIgnoreSelectStatus(row.id)">{{ row.title }}</option>
        </select>
        <span v-if="validation && validation.status" class="fv-plugins-message-container invalid-feedback">
            {{ validation.status[0] }}
        </span>
      </div>

    </div>
    <div class="card-footer pl-0 pr-0 pb-0">
      <div class="row">
        <div class="col-lg-6">
          <button type="reset" class="btn btn-primary mr-2" :disabled="!data.id || !data.status" @click="save">{{ $t('save') }}</button>
          <button type="reset" class="btn btn-secondary" @click="cancel">{{ $t('cancel') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ApiService from "@/core/services/api.service";
import Vue from "vue";

export default {
  name: "form-general-change-status-modal",
  props: {
    hideModal: {type: Function},
    handlingData: {type: Function},
    statusList: {type: Array},
    ignoreSelectedStatus: {type: Array, default: () => [], required: false},
    url: {type: String},
    urlInvoiceList: {type: String},
    handlingStatus: {type: Function},
    showConfirmMessage: {type: Boolean, default: false},
  },
  data() {
    return {
      data: {
        status: null,
        id: null,
      },
      validation: null,
      invoices_list: [],
      invoice: null,
      invoice_date: null,
      from_status: null,
    };
  },
  methods: {
    save() {
      this.changeStatus();
    },
    cancel() {
      this.$emit('hide-modal');
      this.resetAfterSave();
      this.finishedSave();
    },
    finishedSave(su_object) {
      this.$emit('handling-data', su_object);
    },
    changeStatus(params = []) {
      if (this.data.id && this.data.status)
      ApiService.post(this.url, {
        id: this.data.id,
        status: this.data.status,
        ...params
      }).then((response) => {
        this.$successAlert(response.data.message);
        this.cancel();
        Vue.prototype.$postStatus = true;
      }).catch(error => {
        Vue.prototype.$postStatus = true;
        this.$errorAlert(error);
        // if (this.showConfirmMessage) {
        //   if (error.response.data && error.response.data.errors && error.response.data.errors.balance > 0) {
        //     this.$confirmAlert(
        //         error.response.data.message ? error.response.data.message : '',
        //         this.changeStatus,
        //         {action: 'confirm'}
        //     );
        //   } else {
        //     this.$errorAlert(error);
        //   }
        // } else {
        //   this.$errorAlert(error);
        // }


      });
    },
    resetAfterSave() {
      this.data.status = null;
      this.data.id = null;
      this.invoices_list = [];
      this.invoice = null;
      this.invoice_date = null;
      this.from_status = null;
    },
    checkIgnoreSelectStatus(status) {
      if (this.ignoreSelectedStatus.length > 0){
        return this.ignoreSelectedStatus.includes(status)
      }
      return false;
    },
    selectInvoice(_invoice){
      if (_invoice){
        this.data.id = _invoice.id;
        this.invoice_date = _invoice.create_date;
      }else {
        this.data.id = null;
        this.invoice_date = null;
      }
    },
    getInvoices: _.debounce(function () {
      this.invoices_list = [];
      if (this.from_status)
        ApiService.get(`${this.urlInvoiceList}?status=${this.from_status}`).then((response) => {
          this.invoices_list = response.data.data;
        });
    }, 100),
  },

  mounted() {
  },
};
</script>